import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import Card from './index-card';


export default function ResponsiveGrid({content}) {

  return (
    <Box sx={{ mt: 10, mb: 10 }}>
      <Typography variant="h4" align="center" gutterBottom>
        <strong>Services to Support Your Business Operations</strong>
      </Typography>
      <Typography variant="h6" align="center" gutterBottom>
        <strong>Supporting your business through complementary support and services</strong>
      </Typography>
      <Box sx={{ flexGrow: 1, mt: 5 }}>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, md: 16 }}>
          {content?.services && content?.services.map((service, index) => (
            <Grid key={index} size={{ xs: 2, sm: 4, md: 4 }}>
              <Card title={service.title} description={service.desciption} icon={service.icon} target={service.target} />
            </Grid>
          ))}
        </Grid>
      </Box>
      </Box>
  );
}
